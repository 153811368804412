import { m } from '../../n2/components/base.js'
import { CrudTable } from '../../n2/components/crud.js'
import App from '../state/App.js'
import FramedPage from '../components/FramedPage.js'

export default class AdminTestersPage extends FramedPage {
    getTitle() {
        return 'Stammdaten'
    }

    getSubTitle() {
        return 'Tester'
    }

    renderMainComponent() {
        return m(CrudTable, {
            singularName: 'Tester',
            pluralName: 'Tester',
            dataSource: App.users,
            columns: [
                {
                    title: 'E-Mail-Adresse',
                    property: 'email',
                    type: 'string',
                    order: true,
                    filter: true
                },
                {
                    title: 'Name',
                    property: 'name',
                    type: 'string',
                    order: true,
                    filter: true
                },
                {
                    title: 'Testzentrum',
                    property: 'center',
                    type: 'reference',
                    lookup: App.centers.all,
                    order: true,
                    filter: true
                }
            ],
            configureModel: (model) => {
                model.mandatory('email,firstName,lastName', true)
                model.options('center', App.centers.all)
            },
            renderForm: (form) => form.group([
                form.string('email', 'E-Mail-Adresse'),
                form.hgroup([
                    form.string('firstName', 'Vorname'),
                    form.string('lastName', 'Nachname'),
                ]),
                form.selectable('center', 'Zugewiesenes Testzentrum'),
                form.string('password', 'Passwort', { type: 'password' })
            ]),
            renderInfo: this.renderInfo
        })
    }
}