import {m, Control} from '../../n2/components/base.js'

export default class AppPage extends Control {
    constructor(vnode) {
        super(vnode, '.w-full')
    }

    renderChildren() {
        return m('h1', this.constructor.name)
    }
}
