import Entity from './Entity.js'
import DataConsumer from './DataConsumer.js'
import {ObjectEvent} from '../ObjectEvent.js'

const computeNumberOfPages = (pageSize, numberOfEntities) =>
    numberOfEntities === 0 ? 0 : Math.ceil(numberOfEntities / pageSize)

const computePageIndices = (pageSize, entityIndex) =>
    pageSize > 0 ? [Math.floor(entityIndex / pageSize), entityIndex % pageSize] : [0, 0]

class PropertyFilter {
    constructor(model, property, operation) {
        this.model = model
        this.property = property
        this.operation = operation
    }

    getProperty() {
        return this.property
    }

    getOperation() {
        return this.operation
    }

    setOperation(operation) {
        if (this.operation !== operation) {
            this.operation = operation
            this.model.invalidate()
        }
    }

    getValue() {
        return this.value
    }

    setValue(value) {
        if (value !== this.value) {
            this.value = value
            this.model.invalidate()
        }
    }
}

export default class TableModel extends DataConsumer {
    constructor(dataSource) {
        super(dataSource)
        this.onInvalidate = new ObjectEvent(this)
        this.pages = []
        this.pageSize = 10
        this.totalNumber = 0
        this.currentNumber = 0
        this.orderByProperty = undefined
        this.orderByMode = 'none'
        this.filters = {}
    }

    onDataSourceChanged(dataSource, operation, id) {
        super.onDataSourceChanged(dataSource, operation, id)
        this.invalidate()
    }

    getPageSize() {
        return this.pageSize
    }

    setPageSize(pageSize) {
        if (pageSize !== pageSize) {
            this.pageSize = pageSize
            this.invalidate()
        }
    }

    getEntityPageIndices(entityIndex) {
        return computePageIndices(this.pageSize, entityIndex)
    }

    getTotalNumberOfEntities() {
        return this.totalNumber
    }

    getNumberOfEntities() {
        return this.currentNumber
    }

    getTotalNumberOfPages() {
        return computeNumberOfPages(this.pageSize, this.totalNumber)
    }

    getNumberOfPages() {
        return computeNumberOfPages(this.pageSize, this.currentNumber)
    }

    async loadPage(pageIndex) {
        const state = this.getPageState(pageIndex)
        if (state !== 'unavailable') {
            this.pages[pageIndex] = 'loading'
            return await this.load(this.pageSize, pageIndex)
        }
    }

    async ensurePage(pageIndex) {
        const state = this.getPageState(pageIndex)
        if (!(state === 'unavailable' || state === 'loaded' || state === 'loading')) {
            await this.loadPage(pageIndex)
        }
    }

    async loadEntity(entityIndex) {
        return await this.load(1, entityIndex)
    }

    async ensureEntity(entityIndex) {
        const state = this.getEntityState(entityIndex)
        if (!(state === 'unavailable' || state === 'loaded' || state === 'loading')) {
            return await this.load(1, entityIndex)
        }
    }

    getEntity(entityIndex) {
        const [pageIndex, innerPageIndex] = computePageIndices(this.pageSize, entityIndex)
        const page = this.pages[pageIndex]
        return page && page[innerPageIndex]
    }

    getLoadedEntities() {
        const entities = []
        for(const page of this.pages) {
            if (Array.isArray(page)) {
                for (const entity of page) {
                    if (entity !== undefined) {
                        entities.push(entity)
                    }
                }
            }
        }
        return entities
    }

    getEntityIndex(criteria) {
        let index = 0
        for(const page of this.pages) {
            if (Array.isArray(page)) {
                for (const entity of page) {
                    if (entity !== undefined && criteria(entity)) {
                        return index
                    }
                    index++
                }
            } else {
                index += this.pageSize
            }
        }
        return -1
    }

    getEntityState(entityIndex) {
        const [pageIndex, innerPageIndex] = computePageIndices(this.pageSize, entityIndex)
        const pageState = this.getPageState(pageIndex)
        if (pageState === 'loaded') {
            return this.pages[pageIndex][innerPageIndex] ? 'loaded' : 'unavailable'
        }
        return pageState
    }

    getPageState(pageIndex) {
        const page = this.pages[pageIndex]
        if (Array.isArray(page)) {
            return 'loaded'
        } else if (page) {
            return page
        } else if (pageIndex > this.getNumberOfPages()) {
            return 'unavailable'
        } else {
            return 'unloaded'
        }
    }

    getPageEntities(pageIndex) {
        return Array.isArray(this.pages[pageIndex]) ? [...this.pages[pageIndex]] : []
    }

    addPropertyFilter(property, operation, value) {
        let propertyFilters = this.filters[property]
        if (!propertyFilters) {
            propertyFilters = this.filters[property] = []
        }
        const filter = new PropertyFilter(this, property, operation)
        propertyFilters.push(filter)
        if (value !== undefined) {
            filter.setValue(value)
        }
        this.invalidate()
        return filter
    }

    getPropertyFilters(property) {
        return this.filters[property] ? [...this.filters[property]] : []
    }

    getAllFilters() {
        const filters = []
        for(const propertyFilters of Object.values(this.filters)) {
            for(const propertyFilter of propertyFilters) {
                filters.push(propertyFilter)
            }
        }
        return filters
    }

    removePropertyFilter(propertyFilter) {
        if (propertyFilter && propertyFilter.collection === this) {
            const propertyFilters = this.filters[propertyFilter.property]
            if (propertyFilters) {
                const filterIndex = propertyFilters.findIndex(f => f === propertyFilter)
                if (filterIndex >= 0) {
                    delete propertyFilter.collection
                    delete propertyFilters[filterIndex]
                    this.invalidate()
                }
            }
        }
    }

    clearPropertyFilters(property) {
        if (this.filters[property]) {
            delete this.filters[property]
            this.invalidate()
        }
    }

    clearAllFilters() {
        this.filters = {}
        this.invalidate()
    }

    getOrderByProperty() {
        return this.orderByProperty
    }

    setOrderByProperty(property) {
        this.orderByProperty = property
        if (this.orderByMode !== 'none') {
            this.invalidate()
        }
    }

    getOrderByMode() {
        return this.orderByMode
    }

    setOrderByMode(mode) {
        this.orderByMode = mode
        this.invalidate()
    }

    invalidate() {
        this.pages = []
        this.onInvalidate.dispatch()
    }

    async load(pageSize, pageIndex) {
        const params = {
            pageSize,
            pageIndex
        }
        if (this.orderByMode !== 'none' && this.orderByProperty !== undefined) {
            params.orderBy = this.orderByProperty
            params.orderDirection = this.orderByMode
        }
        const filters = []
        for(const propertyFilter of this.getAllFilters()) {
            const value = propertyFilter.getValue()
            if (value !== undefined) {
                const filter = {
                    property: propertyFilter.getProperty(),
                    value
                }
                const operation = propertyFilter.getOperation()
                if (operation !== undefined) {
                    filter.operation = operation
                }
                filters.push(filter)
            }
        }
        if (filters.length > 0) {
            params.filters = filters
        }

        const result = await this.dataSource.query(params)

        if ((result.total !== undefined && result.total !== this.totalNumber) ||
            (result.remaining !== undefined && result.remaining !== this.currentNumber)
        ) {
            this.totalNumber = result.total
            this.currentNumber = result.remaining
            this.invalidate()
        }
        this.pages[pageIndex] = result.entities
    }
}
