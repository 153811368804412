
import User from './User.js'
import DataSource from '../../n2/data/DataSource.js'

const baseUrl = '/'
const dataSource = (collectionName, fetchAll, fetchImmediately) => {
    const ds = new DataSource(baseUrl + 'data/' + collectionName, fetchAll)
    if (fetchImmediately) ds.reloadAll()
    return ds
}

const users = dataSource('users', true)
const user = new User(users)

const loggedInSources = {
    users,
    centers: dataSource('centers', true),
    companies: dataSource('companies', true),
    testTools: dataSource('test-tools', true),
    bookings: dataSource('bookings'),
    tests: dataSource('tests'),
}

user.onAvailabilityChange.register(
    (a, available) => {
        Object.values(loggedInSources).map(ds => available ? ds.reloadAll() : ds.clearAll())
        if (!available) user.reset()
    }
)

const App = {
    user,
    ...loggedInSources,
    baseUrl
}

export default App
