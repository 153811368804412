import {m} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'
import {Layout} from '../../n2/components/layout.js'
import Card from '../../shared/components/Card.js'

export default class TestCentersPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    renderChildren() {
        return m(Layout.PaddedVBox, App.centers.all.array.map(center => m(Card, {
            title: center.name,
            subtitle: center.city,
            cardAction: () => m.route.set('/centers/' + center.id),
            size: 'lg',
            text: 'Aufrufen'
        })))
    }
}