import { Control, Button, Icon, m } from '../../n2/components/base.js'
import Card from '../../shared/components/Card.js'
import { CalendarIcon } from '../../n2/icons.js'
import {
    PlusIcon,
    MinusIcon,
} from '../../n2/icons.js'
import Theme from '../../theme.js'

export default class SlotCards extends Control {
    constructor(vnode) {
        super(vnode, '.flex.flex-wrap')
        this.date = new Date()
        this.expandedSlot = undefined
    }

    getAttributes() {
        const attrs = super.getAttributes()
    }

    formatDate(date) {
        const newDate = new Date(date)
        return ("0" + newDate.getHours()).slice(-2) + '.' + ("0" + newDate.getMinutes()).slice(-2);
    }

    setExpanded(index) {
        if (this.expandedSlot === index) {
            this.expandedSlot = undefined
        } else {
            this.expandedSlot = index
        }
    }

    renderCard(slot, index) {
        let cardAction = undefined;
        if (this.attrs.isBooking && slot.allowed && this.attrs.cardAction !== undefined) {
            cardAction = () => this.attrs.cardAction(slot)
        }
        const startDate = new Date(slot.start)
        const endDate = new Date(slot.end)
        let textColor = '.text-white'
        let iconColor = Theme.colors.white
        let backgroundColor = ''
        if (this.date >= startDate && this.date < endDate) {
            backgroundColor = '.bg-success '
        } else if (slot.exceeded !== undefined && slot.exceeded) {
            backgroundColor = '.bg-danger '
        } else if (slot.allBookings.length < 1) {
            backgroundColor = '.bg-gray-light '
            textColor = '.text-gray'
            iconColor = Theme.colors.gray.DEFAULT
        } else {
            backgroundColor += '.bg-brand1'
        }
        const borderRadius = '.rounded-lg'

        return [
            m('.flex.w-full.mb-2.flex-col' + backgroundColor + textColor + borderRadius + '.shadow.items-center.border.border-gray.hover:border-brand1.cursor-pointer.justify-between.z-0',
                [
                    m('.flex.flex-row.w-full.justify-between.p-3',
                        { onclick: () => this.setExpanded(index) },
                        [
                            m('.flex', [
                                m('p.font-bold ' + textColor, this.formatDate(slot.start) + ' - ' + this.formatDate(slot.end))
                            ]),
                            m('.flex', [
                                m('p.font-bold.mr-4' + textColor, 'Buchungen: ' + slot.allBookings.length),
                                m(Icon, {
                                    iconFace: this.expandedSlot === index ? MinusIcon : PlusIcon,
                                    color: iconColor,
                                    size: 18
                                })
                            ])
                        ]
                    ),
                    m('.w-full.bg-white.text-black.p-3.rounded-b-lg.border-t.justify-between' + (this.expandedSlot === index ? '.flex.flex-row' : '.hidden'), [
                        m('.flex.flex-col', [
                            m('p.text-black', "Buchungen: "),
                            slot.allBookings.length > 0
                                ? m('ul', [
                                    slot.allBookings.map(booking => m('li', [
                                        m(Button, {
                                            class: 'my-1 hover:underline',
                                            onclick: () => this.attrs.onBookingClick(booking.number)
                                        }, booking.firstname + ' ' + booking.lastname)

                                    ]))
                                ])
                                : m('p', '-')
                        ]),
                        m('.flex.flex-col.pr-12', [
                            m('p.text-black', "Kapazität: " + slot.capacity),
                            m('p.text-black', "Freie Plätze: " + slot.free),
                        ]),
                ])
            ])
        ]
    }

    renderChildren() {
        return this.attrs.slots.map((slot, index) => this.renderCard(slot, index))
    }
}
