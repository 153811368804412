import { m } from '../../n2/components/base.js'
import { CrudTable } from '../../n2/components/crud.js'
import App from '../state/App.js'
import FramedPage from '../components/FramedPage.js'
import weekplan from '../weekplan.js'

const isEmpty = (txt) => !txt || txt.trim().length === 0

export default class PictogramPage extends FramedPage {
    getTitle() {
        return 'Stammdaten'
    }

    getSubTitle() {
        return 'Testzentren'
    }

    renderMainComponent() {
        return m(CrudTable, {
            singularName: 'Testzentrum',
            pluralName: 'Testzentren',
            dataSource: App.centers,
            columns: [
                {
                    title: 'Name',
                    property: 'name',
                    type: 'string',
                    order: true,
                    filter: true
                },
                {
                    title: 'Unternehmen',
                    property: 'company',
                    type: 'reference',
                    lookup: App.companies.all,
                    order: true,
                    filter: true
                }
            ],
            configureModel: (model) => {
                model.mandatory('name,company,addressStreet,addressNumber,addressZip,addressCity,addressCountry,directions,headOfTest,email,phone', true)
                model.options('company', App.companies.all)
                model.onPrepareInboundEntity((e) => {
                    e.plan = Array.isArray(e.plan) ? weekplan.planToText(e.plan) : ''
                })
                model.onPrepareOutboundEntity((e) => {
                    e.plan = isEmpty(e.plan) ? null : weekplan.parse(e.plan)
                })
                model.addConstraint('plan', (e) => {
                    if (isEmpty(e.plan)) {
                        return true
                    }
                    try {
                        weekplan.parse(e.plan)
                    } catch(ex) {
                        return ex.message
                    }
                    return true
                })
            },
            renderForm: (form) => form.group([
                form.string('name', 'Bezeichnung'),
                form.highlighted([
                    form.text('plan', 'Wochenplan', { rows: 5, style: { fontFamily: 'monospace' } }),
                    form.number('bookingInAdvance', 'Buchbarkeit im Voraus (Tage)')
                ]),
                form.selectable('company', 'Unternehmen'),
                form.hgroup([
                    form.string('addressStreet', 'Straße'),
                    form.string('addressNumber', 'Hausnummer'),
                ]),
                form.string('addressAddition', 'Adresszusatz'),
                form.hgroup([
                    form.string('addressZip', 'Postleitzahl'),
                    form.string('addressCity', 'Stadt/Ort'),
                ]),
                form.string('addressCountry', 'Land'),
                form.text('directions', 'Anfahrt'),
                form.string('email', 'E-Mail'),
                form.hgroup([
                    form.string('headOfTest', 'Testzentrum-Leiter'),
                    form.string('phone', 'Telefon'),
                ]),
                form.hgroup([
                    form.string('headOfMedical', 'Ärztlicher Leiter'),
                    form.string('fax', 'Fax'),
                ])
            ]),
            renderInfo: this.renderInfo
        })
    }
}
