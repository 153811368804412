import {m, Button, Input} from '../../n2/components/base.js'
import AppPage from '../components/AppPage.js'
import App from '../state/App.js'
import {DatePicker, DateRangePicker, DateRangeSheet} from '../../n2/components/time.js'
import SlotCards from '../components/SlotCards.js'
import {Modal} from '../../n2/components/modal.js'
import {Form} from '../../n2/components/form.js'
import {toDateString, toTimeString} from '../../n2/dateTime.js'
import QRCode from '../../vendor/qrcode.min.js'

export default class TestCenterPage extends AppPage {
    constructor(vnode) {
        super(vnode)
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        this.date = date
        this.slots = []
        this.loadDaySlots()
    }

    oninit(vnode) {

    }

    getCenterId() {
        return Number(m.route.param('center'))
    }

    getCenter() {
        return App.centers.all.byId[this.getCenterId()]
    }

    printTestDialog(test) {
        window.open(App.tests.getEntityCommandGetUrl(test.id, 'loadDocument'))
    }

    printTestSheetDialog(test) {
        QRCode.toDataURL(test.number, {scale: 16}, (error, imageUrl) => {
            if (error) throw error
            const printWin = window.open('', 'PRINT', 'height=560,width=400,toolbar=0,location=0,directories=0,status=0,menubar=0')
            printWin.document.head.innerHTML =
                ('<style media="print">#print-button { display: none }</style><title>' + 'Testbogen ' + test.number  + '</title>')
            printWin.document.body.innerHTML =
                ('<div style="text-align: center">') +
                    ('<img width="320px" height="320px" src="' + imageUrl + '" alt="' + test.number + '"/>') +
                    ('<h4>' + test.number + '</h4>') +
                    ('<h4>' + test.probandFirstName + ' ' + test.probandLastName + '</h4>') +
                    ('<button id="print-button" style="width: 300px; height: 80px" onclick="window.print()">Ausdrucken</button>') +
                ('</div>')
        })
    }

    async bookingDialog(booking) {
        const result = await Form.openModal(
            App.tests,
            'create',
            {
                booking: booking.id,
                center: this.getCenterId(),
                user: App.user.getId()
            },
            (model) => {
                model.onPrepareOutboundEntity((entity) => {
                    delete entity.proband
                })
                model.onPrepareInboundEntity((entity) => {
                    entity.proband = booking.proband
                })
                model.setSubModelConfigurator('proband', (probandModel) => {
                    probandModel.setOperation('view')
                })
                model.setAlwaysTainted(true)
            },
            (form) => {
                const startTime = booking.startTime
                const endTime = booking.endTime
                const now = new Date()
                const inTime = now < startTime ? ('zu früh') : (now > endTime ? 'verspätet' : 'pünktlich')
                const bookingTime = toDateString(startTime) + ' - von ' + toTimeString(startTime) + ' bis ' + toTimeString(endTime) + ' (' + inTime + ')'
                return [
                    form.readOnly('Gebuchter Zeitraum', bookingTime),
                    form.highlighted([
                        form.form('proband', undefined, (probandForm) => {
                            return [
                                probandForm.hgroup([
                                    probandForm.string('firstName', 'Vorname'),
                                    probandForm.string('lastName', 'Nachname'),
                                ]),
                                probandForm.string('passId', 'Ausweisnummer'),
                                probandForm.textualDate('birthday', 'Geburtsdatum'),
                                probandForm.hgroup([
                                    probandForm.string('addressStreet', 'Straße'),
                                    probandForm.string('addressNumber', 'Hausnummer'),
                                ]),
                                probandForm.string('addressAddition', 'Adresszusatz'),
                                probandForm.hgroup([
                                    probandForm.string('addressZip', 'Postleitzahl'),
                                    probandForm.string('addressCity', 'Stadt/Ort'),
                                ]),
                                probandForm.string('addressCountry', 'Land'),
                                probandForm.string('passId', 'Ausweisnummer'),
                                probandForm.string('phone', 'Telefon'),
                            ]
                        })
                    ])
                ]
            },
            'Test anlegen'
        )
        if (result && result.entity && result.entity.number) this.printTestSheetDialog(result.entity)
    }

    async testResultDialog(test) {
        const result = await Form.openModal(
            App.tests,
            test.final ? 'view' : 'update',
            test,
            (model) => {
                model.mandatory('testTool,result')
                model.options('testTool', App.testTools.all)
                model.options('result', [
                    { id: true, name: 'Positiv', color: '#fff', backgroundColor: '#f00' },
                    { id: false, name: 'Negativ', color: '#fff', backgroundColor: '#080' },
                ])
            },
            (form) => {
                const entity = form.getModel().getEntity()
                return [
                    form.highlighted([
                        form.hgroup([
                            form.readOnly('Vorname', entity.probandFirstName),
                            form.readOnly('Nachname', entity.probandLastName),
                        ]),
                        form.readOnly('Ausweisnummer', entity.probandPassId),
                    ]),
                    form.selectable('testTool', 'Testmittel'),
                    form.selectable('result', 'Testergebnis'),
                    form.text('remarks', 'Bemerkungen')
                ]
            },
            test.final ? 'Test ansehen' : 'Test abschließen'
        )
        if (result && result.entity && !result.entity.proband) {
            this.printTestDialog(result.entity)
        } else {
            this.loadDaySlots()
        }
    }

    async testCreateDialog() {
        const result = await Form.openModal(
            App.tests,
            'create',
            {
                center: this.getCenterId(),
                user: App.user.getId()
            },
            (model) => {
                model.mandatory('probandFirstName,probandLastName,probandAddressStreet,probandAddressNumber,probandAddressZip,probandAddressCity,probandAddressCountry,probandBirthday,probandPassId')
                model.addConstraint('probandBirthday', (e) => {
                    if (!(e.probandBirthday instanceof Date)) return 'Ungültiges Datumsformat'
                    const tooOld = new Date()
                    tooOld.setFullYear(tooOld.getFullYear() - 130)
                    if (e.probandBirthday < tooOld || e.probandBirthday > new Date()) return 'Ungültiges Geburtsdatum'
                    const tooYoung = new Date()
                    tooYoung.setFullYear(tooYoung.getFullYear() - 12)
                    if (e.probandBirthday > tooYoung) return 'Kinder unter 12 Jahren sind zum Test nicht zugelassen'
                    return true
                })
            },
            (form) => {
                return [
                    form.hgroup([
                        form.string('probandFirstName', 'Vorname'),
                        form.string('probandLastName', 'Nachname'),
                    ]),
                    form.string('probandPassId', 'Ausweisnummer'),
                    form.textualDate('probandBirthday', 'Geburtsdatum'),
                    form.hgroup([
                        form.string('probandAddressStreet', 'Straße'),
                        form.string('probandAddressNumber', 'Hausnummer'),
                    ]),
                    form.string('probandAddressAddition', 'Adresszusatz'),
                    form.hgroup([
                        form.string('probandAddressZip', 'Postleitzahl'),
                        form.string('probandAddressCity', 'Stadt/Ort'),
                    ]),
                    form.string('probandAddressCountry', 'Land'),
                    form.string('probandPhone', 'Telefon'),
                ]
            },
            'Test anlegen'
        )
        if (result && result.entity) this.printTestSheetDialog(result.entity)
    }

    async checkCode(uuid) {
        if(uuid !== undefined) this.code = uuid
        if (this.code) {
            let result
            try {
                result = await App.centers.callCollectionCommand('find', this.code)
            } catch (ex) {
                if (ex.code === 400 || ex.code === 404) {
                    return await Modal.alert(ex.message)
                } else {
                    Modal.alert('Ein Fehler ist aufgetreten.')
                    throw ex
                }
            }
            if (result.type === 'booking') {
                this.bookingDialog(result.document)
            } else if (result.type === 'test') {
                this.testResultDialog(result.document)
            }
        }
        return false
    }

    onBookingClick(uuid) {
        this.checkCode(uuid)
    }

    async loadDaySlots() {
        const from = this.date
        const till = new Date(this.date.getTime())
        till.setDate(till.getDate() + 1)
        this.slots = await App.centers.callEntityCommand(this.getCenterId(), 'getSlots', from, till)
    }

    async testReportDialog() {
        const modal = new Modal({
            title: 'Zusammenfassung ' + this.getCenter().name,
            close: true,
            cancel: {
                kind: 'primary',
                label: 'Schließen'
            },
            renderContent: () => [
                m(DateRangeSheet, {
                    nullable: false,
                    value: modal.dateRange,
                    onChange: v => {
                        modal.dateRange = v
                        updateRange()
                    }
                }),
                m('table.w-96.ml-16.table-auto.ml-6',
                    m('thead', [
                        m('tr', [
                            m('th.text-left', 'Datum'),
                            m('th.text-center', 'Positiv'),
                            m('th.text-center', 'Negativ'),
                            m('th.text-center', 'Gesamt')
                        ]),
                    ]),
                    m('tbody',
                        [
                            Object.entries(modal.result).map(([key, {positive, negative}], index) => {
                                const overall = negative + positive
                                return m('tr', { class: index % 2 === 0 ? 'bg-gray-lightest' : '' }, [
                                    m('td.text-left', key),
                                    m('td.text-center.font-bold.text-danger', positive > 0 ? positive : ''),
                                    m('td.text-center.font-bold.text-success', negative > 0 ? negative : ''),
                                    m('td.text-center.font-bold', overall > 0 ? overall : '')
                                ])
                            })
                        ]
                    )
                )
            ]
        })
        const updateRange = () => {
            App.tests.callCollectionCommand('report', this.getCenterId(), modal.dateRange[0], modal.dateRange[1]).then(result => {
                modal.result = result
            })
        }
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        modal.dateRange = [today, today]
        modal.result = {}
        updateRange()
        return modal.show()
    }

    renderChildren() {
        return [
            m('.flex.flex-row.justify-between', [
                m('.flex.flex-col.flex-grow.px-2.w-1/2.mr-4', [
                    m('.flex', [
                        m(Input, {
                            class: 'font-mono mb-4 py-2 block w-full md:w-96',
                            spellcheck: false,
                            type: 'text',
                            placeholder: 'Code eingeben oder scannen',
                            value: this.code,
                            onfocus: (ev) => this.code = '',
                            autofocus: true,
                            onChange: (v) => this.code = v,
                        }),
                        m(Button, {
                            class: 'h-10 w-28',
                            onclick: () => this.checkCode(),
                        }, 'Öffnen...'),
                    ]),
                    m('.flex', [
                        m(Button, {
                            class: 'h-10 w-64 mb-4',
                            onclick: () => this.testCreateDialog(),
                        }, 'Test direkt anlegen...'),
                    ]),
                    App.user.isAdmin() && m('.flex', [
                        m(Button, {
                            class: 'h-10 w-64',
                            onclick: () => this.testReportDialog(),
                        }, 'Bericht...'),
                    ])
                ]),
                m('.flex.flex-col.flex-grow.px-2.w-1/2.xl:w-1/3', [
                    m(DatePicker, {
                        class: 'py-1',
                        value: this.date,
                        onChange: (v) => {
                            this.date = v
                            this.loadDaySlots()
                        }
                    }),
                    m('.flex.mt-4.w-full.overflow-y-auto', {style: {maxHeight: '75vh'}}, [
                        this.slots.length > 0
                            ? m(SlotCards, {
                                slots: this.slots,
                                showStatus: true,
                                onBookingClick: (uuid) => this.onBookingClick(uuid)
                            })
                            : m('p.text-center', "Für das ausgewählte Datum sind keine Slots vorhanden")
                    ])
                ]),
            ])
        ]
    }
}
