import { m, Control } from '../../n2/components/base.js'
import App from '../state/App.js'
export default class LoginFrame extends Control {
    constructor(vnode) {
        super(vnode, '.w-full.h-screen.min-h-screen.overflow-hidden.flex')
    }

    oninit() {
        if (App.user.isLoggedIn()) {
            m.route.set('/admin')
        }
    }

    getTitle() {
        return this.attrs.title || ''
    }

    renderLogo() {
        return m('img.mb-6.mt-4.mx-auto', {
            src: '/assets/logo-left.svg',
            style: 'width: 200px;'
        })
    }

    renderHeader() {
        return m('.w-full.text-center.font-bold.text-brand1', [
            this.getTitle()
        ])
    }

    renderChildren() {
        return [m('.container.p-4.mx-auto.flex.flex-col.items-center.justify-center', [
            m('.div.border.border-gray-lightest.bg-white.w-full.sm:w-3/4.lg:w-2/4.xl:w-2/5', [
                m('.self-center.flex.flex-col.shadow-lg.py-9.px-4.sm:px-9', [
                    this.renderLogo(),
                    this.vnode.children])
                ])
            ])
        ];
    }
}
