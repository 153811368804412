import {Theme, Icon, m} from '../../n2/components/base.js'
import FramedPage from './FramedPage.js'

export default class MenuPage extends FramedPage {
    getMenuGroups() {
        return this.attrs.menu || []
    }

    renderMenuEntry(menuEntry) {
        return m(m.route.Link, { href: menuEntry.route },
            m('.flex.mb-4.w-full.sm:w-40.h-36.flex-col.bg-white.rounded-sm.mr-3.mt-3.items-center.font-bold.text-sm', [
                m(Icon, {
                    class: 'p-4',
                    iconFace: menuEntry.iconFace,
                    color: Theme.colors.gray.DEFAULT,
                    size: 128
                }),
                m('.bg-brand1.w-full.h-full.rounded-b-sm.text-center.text-white.p-1', menuEntry.title)
            ])
        )
    }

    renderMenuGroup(menuGroup) {
        return m('.flex.flex-col.mt-5.text-center', [
            menuGroup.title,
            m('.flex.flex-col.sm:flex-row.flex-wrap.justify-center', menuGroup.entries.map(menuEntry => this.renderMenuEntry(menuEntry)))
        ])
    }

    renderMainComponent() {
        return this.getMenuGroups().map(menuGroup => this.renderMenuGroup(menuGroup))
    }
}
