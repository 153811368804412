const module = {}
/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { start: peg$parsestart },
      peg$startRuleFunction  = peg$parsestart,

      peg$c0 = function(head, tail) {
              return [ head ].concat(tail.map(e => e[1]))
          },
      peg$c1 = function(head, tail) {
              return {
                  type: 'week',
                  date: head,
                  dows: tail.map(e => e[1]),
              }
          },
      peg$c2 = function(head, tail) {
              return {
                  type: 'week',
                  date: 'default',
                  dows: [head].concat(tail.map(e => e[1])),
              }
          },
      peg$c3 = function(head, tail) {
              return {
                  type: 'day',
                  date: head,
                  plan: tail,
              }
          },
      peg$c4 = ":",
      peg$c5 = peg$literalExpectation(":", false),
      peg$c6 = function(d) { return d },
      peg$c7 = function(d) {return d},
      peg$c8 = function(s, d) {
              return {
                  days: s,
                  plan: d
              }
          },
      peg$c9 = function() { return { open: false } },
      peg$c10 = function(t, s) {
              return {
                  open: true,
                  time: t,
                  slots: s
              }
          },
      peg$c11 = ",",
      peg$c12 = peg$literalExpectation(",", false),
      peg$c13 = function(minutes) {
              return parseInt(minutes)
          },
      peg$c14 = "(",
      peg$c15 = peg$literalExpectation("(", false),
      peg$c16 = ")",
      peg$c17 = peg$literalExpectation(")", false),
      peg$c18 = function(times, minutes, capacity) {
              return [parseInt(times), parseInt(minutes), parseInt(capacity)]
          },
      peg$c19 = /^[^\n\r]/,
      peg$c20 = peg$classExpectation(["\n", "\r"], true, false),
      peg$c21 = "\n",
      peg$c22 = peg$literalExpectation("\n", false),
      peg$c23 = "\r",
      peg$c24 = peg$literalExpectation("\r", false),
      peg$c25 = peg$anyExpectation(),
      peg$c26 = /^[0-9]/,
      peg$c27 = peg$classExpectation([["0", "9"]], false, false),
      peg$c28 = function() {return text()},
      peg$c29 = "Wochenplan",
      peg$c30 = peg$literalExpectation("Wochenplan", false),
      peg$c31 = "wochenplan",
      peg$c32 = peg$literalExpectation("wochenplan", false),
      peg$c33 = "Plan",
      peg$c34 = peg$literalExpectation("Plan", false),
      peg$c35 = "plan",
      peg$c36 = peg$literalExpectation("plan", false),
      peg$c37 = "ab",
      peg$c38 = peg$literalExpectation("ab", false),
      peg$c39 = "Ab",
      peg$c40 = peg$literalExpectation("Ab", false),
      peg$c41 = "x",
      peg$c42 = peg$literalExpectation("x", false),
      peg$c43 = "Geschlossen",
      peg$c44 = peg$literalExpectation("Geschlossen", false),
      peg$c45 = "geschlossen",
      peg$c46 = peg$literalExpectation("geschlossen", false),
      peg$c47 = "Pause",
      peg$c48 = peg$literalExpectation("Pause", false),
      peg$c49 = "pause",
      peg$c50 = peg$literalExpectation("pause", false),
      peg$c51 = ".",
      peg$c52 = peg$literalExpectation(".", false),
      peg$c53 = " ",
      peg$c54 = peg$literalExpectation(" ", false),
      peg$c55 = "\t",
      peg$c56 = peg$literalExpectation("\t", false),
      peg$c57 = "mo",
      peg$c58 = peg$literalExpectation("mo", false),
      peg$c59 = "MO",
      peg$c60 = peg$literalExpectation("MO", false),
      peg$c61 = "Mo",
      peg$c62 = peg$literalExpectation("Mo", false),
      peg$c63 = function() { return 0 },
      peg$c64 = "di",
      peg$c65 = peg$literalExpectation("di", false),
      peg$c66 = "DI",
      peg$c67 = peg$literalExpectation("DI", false),
      peg$c68 = "Di",
      peg$c69 = peg$literalExpectation("Di", false),
      peg$c70 = function() { return 1 },
      peg$c71 = "mi",
      peg$c72 = peg$literalExpectation("mi", false),
      peg$c73 = "MI",
      peg$c74 = peg$literalExpectation("MI", false),
      peg$c75 = "Mi",
      peg$c76 = peg$literalExpectation("Mi", false),
      peg$c77 = function() { return 2 },
      peg$c78 = "do",
      peg$c79 = peg$literalExpectation("do", false),
      peg$c80 = "DO",
      peg$c81 = peg$literalExpectation("DO", false),
      peg$c82 = "Do",
      peg$c83 = peg$literalExpectation("Do", false),
      peg$c84 = function() { return 3 },
      peg$c85 = "fr",
      peg$c86 = peg$literalExpectation("fr", false),
      peg$c87 = "FR",
      peg$c88 = peg$literalExpectation("FR", false),
      peg$c89 = "Fr",
      peg$c90 = peg$literalExpectation("Fr", false),
      peg$c91 = function() { return 4 },
      peg$c92 = "sa",
      peg$c93 = peg$literalExpectation("sa", false),
      peg$c94 = "SA",
      peg$c95 = peg$literalExpectation("SA", false),
      peg$c96 = "Sa",
      peg$c97 = peg$literalExpectation("Sa", false),
      peg$c98 = function() { return 5 },
      peg$c99 = "so",
      peg$c100 = peg$literalExpectation("so", false),
      peg$c101 = "SO",
      peg$c102 = peg$literalExpectation("SO", false),
      peg$c103 = "So",
      peg$c104 = peg$literalExpectation("So", false),
      peg$c105 = function() { return 6 },
      peg$c106 = "-",
      peg$c107 = peg$literalExpectation("-", false),
      peg$c108 = function(d1, d2) {
              return [d1, d2]
          },
      peg$c109 = function(s) { return s },
      peg$c110 = function(hour, minute) {
              return parseInt(hour) * 60 + parseInt(minute)
          },
      peg$c111 = function(day, month, year) {
              return new Date(
                  year + '-' +
                  (month.length === 1 ? '0' + month : month) + '-' +
                  (day.length === 1 ? '0' + day : day) +
                  'T00:00:00'
              )
          },

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parsestart() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsenewline();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parsenewline();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsedefaultplan();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = [];
        s6 = peg$parsenewline();
        if (s6 !== peg$FAILED) {
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parsenewline();
          }
        } else {
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseblock();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = [];
          s6 = peg$parsenewline();
          if (s6 !== peg$FAILED) {
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parsenewline();
            }
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseblock();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseEOL();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c0(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseblock() {
    var s0;

    s0 = peg$parseweekplan();
    if (s0 === peg$FAILED) {
      s0 = peg$parseexceptionplan();
    }

    return s0;
  }

  function peg$parseweekplan() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseweekheader();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parsenewline();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseplanline();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parsenewline();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseplanline();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedefaultplan() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseplanline();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parsenewline();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseplanline();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parsenewline();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseplanline();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c2(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseexceptionplan() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsedayheader();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsedayplan();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c3(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseweekheader() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseAB();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseWS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsedate();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOWS();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 58) {
              s5 = peg$c4;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c5); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c6(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedayheader() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsedate();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c4;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c5); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c7(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseplanline() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseOWS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsedowrange();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseOWS();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 58) {
            s4 = peg$c4;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c5); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseOWS();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsedayplan();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseOWS();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c8(s2, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseclosedplan() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseGESCHLOSSEN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c9();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseopenplan() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseAB();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsetime();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseWS();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseslots();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c10(s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedayplan() {
    var s0;

    s0 = peg$parseclosedplan();
    if (s0 === peg$FAILED) {
      s0 = peg$parseopenplan();
    }

    return s0;
  }

  function peg$parseslots() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseslot();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      s5 = peg$parseOWS();
      if (s5 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 44) {
          s6 = peg$c11;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c12); }
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseOWS();
          if (s7 !== peg$FAILED) {
            s5 = [s5, s6, s7];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseslot();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        s5 = peg$parseOWS();
        if (s5 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s6 = peg$c11;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c12); }
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseOWS();
            if (s7 !== peg$FAILED) {
              s5 = [s5, s6, s7];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseslot();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseslot() {
    var s0;

    s0 = peg$parsetesting();
    if (s0 === peg$FAILED) {
      s0 = peg$parsepause();
    }

    return s0;
  }

  function peg$parsepause() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePAUSE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c4;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c5); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOWS();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDIGITS();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c13(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetesting() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$parseDIGITS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseX();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOWS();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDIGITS();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 40) {
                s6 = peg$c14;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c15); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDIGITS();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 41) {
                    s8 = peg$c16;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c17); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c18(s1, s5, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsechar() {
    var s0;

    if (peg$c19.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c20); }
    }

    return s0;
  }

  function peg$parsenewline() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseOWS();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 10) {
        s2 = peg$c21;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c22); }
      }
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 13) {
          s3 = peg$c23;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 10) {
            s4 = peg$c21;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c22); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEOL() {
    var s0, s1;

    s0 = peg$parsenewline();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      peg$silentFails++;
      if (input.length > peg$currPos) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c25); }
      }
      peg$silentFails--;
      if (s1 === peg$FAILED) {
        s0 = void 0;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseDIGIT() {
    var s0, s1;

    s0 = peg$currPos;
    if (peg$c26.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c27); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c28();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseDIGITS() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseDIGIT();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseDIGIT();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c28();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseTWODIGITS() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseDIGIT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseDIGIT();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c28();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseONEORTWODIGITS() {
    var s0, s1;

    s0 = peg$parseTWODIGITS();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseDIGIT();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c28();
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseFOURDIGITS() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseTWODIGITS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseTWODIGITS();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c28();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePLAN() {
    var s0;

    if (input.substr(peg$currPos, 10) === peg$c29) {
      s0 = peg$c29;
      peg$currPos += 10;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c30); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 10) === peg$c31) {
        s0 = peg$c31;
        peg$currPos += 10;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c32); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c33) {
          s0 = peg$c33;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c34); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c35) {
            s0 = peg$c35;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseAB() {
    var s0;

    if (input.substr(peg$currPos, 2) === peg$c37) {
      s0 = peg$c37;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c38); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c39) {
        s0 = peg$c39;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
    }

    return s0;
  }

  function peg$parseX() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 120) {
      s0 = peg$c41;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c42); }
    }

    return s0;
  }

  function peg$parseGESCHLOSSEN() {
    var s0;

    if (input.substr(peg$currPos, 11) === peg$c43) {
      s0 = peg$c43;
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c44); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c45) {
        s0 = peg$c45;
        peg$currPos += 11;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c46); }
      }
    }

    return s0;
  }

  function peg$parsePAUSE() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c47) {
      s0 = peg$c47;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c48); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c49) {
        s0 = peg$c49;
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c50); }
      }
    }

    return s0;
  }

  function peg$parseDOT() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 46) {
      s0 = peg$c51;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c52); }
    }

    return s0;
  }

  function peg$parseWS() {
    var s0, s1;

    s0 = [];
    if (input.charCodeAt(peg$currPos) === 32) {
      s1 = peg$c53;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c54); }
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 9) {
        s1 = peg$c55;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c56); }
      }
    }
    if (s1 !== peg$FAILED) {
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        if (input.charCodeAt(peg$currPos) === 32) {
          s1 = peg$c53;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c54); }
        }
        if (s1 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 9) {
            s1 = peg$c55;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c56); }
          }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOWS() {
    var s0, s1;

    s0 = [];
    s1 = peg$parseWS();
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$parseWS();
    }

    return s0;
  }

  function peg$parsemo() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c57) {
      s1 = peg$c57;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c58); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c59) {
        s1 = peg$c59;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c60); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c61) {
          s1 = peg$c61;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c62); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c63();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsedi() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c64) {
      s1 = peg$c64;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c65); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c66) {
        s1 = peg$c66;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c67); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c68) {
          s1 = peg$c68;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c69); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c70();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsemi() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c71) {
      s1 = peg$c71;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c72); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c73) {
        s1 = peg$c73;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c74); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c75) {
          s1 = peg$c75;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c76); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c77();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsedo() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c78) {
      s1 = peg$c78;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c79); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c80) {
        s1 = peg$c80;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c81); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c82) {
          s1 = peg$c82;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c83); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c84();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsefr() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c85) {
      s1 = peg$c85;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c86); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c87) {
        s1 = peg$c87;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c88); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c89) {
          s1 = peg$c89;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c90); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c91();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsesa() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c92) {
      s1 = peg$c92;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c93); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c94) {
        s1 = peg$c94;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c95); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c96) {
          s1 = peg$c96;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c97); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c98();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseso() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c99) {
      s1 = peg$c99;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c100); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c101) {
        s1 = peg$c101;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c102); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c103) {
          s1 = peg$c103;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c104); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c105();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsedow() {
    var s0;

    s0 = peg$parsemo();
    if (s0 === peg$FAILED) {
      s0 = peg$parsedi();
      if (s0 === peg$FAILED) {
        s0 = peg$parsemi();
        if (s0 === peg$FAILED) {
          s0 = peg$parsedo();
          if (s0 === peg$FAILED) {
            s0 = peg$parsefr();
            if (s0 === peg$FAILED) {
              s0 = peg$parsesa();
              if (s0 === peg$FAILED) {
                s0 = peg$parseso();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedowspan() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsedow();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseOWS();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 45) {
          s3 = peg$c106;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c107); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseOWS();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsedow();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c108(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedowrange() {
    var s0, s1;

    s0 = peg$parsedowspan();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsedow();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c109(s1);
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parsetime() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseONEORTWODIGITS();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s2 = peg$c4;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c5); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseTWODIGITS();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c110(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedate() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseONEORTWODIGITS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseDOT();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseONEORTWODIGITS();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseDOT();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseFOURDIGITS();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c111(s1, s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};




function dateToText(date) {
    return [date.getDate(), (date.getMonth() + 1), date.getFullYear()].join('.')
}

function dowToText(day) {
    return ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'][day]
}

function dowsToText(days) {
    return Array.isArray(days) ? (dowToText(days[0]) + '-' + dowToText(days[1])) : dowToText(days)
}

function timeToText(time) {
    const minutes = time % 60
    return (time - minutes) / 60 + ':' + (minutes < 10 ? '0' + minutes : minutes)
}

function slotToText(slot) {
    return Array.isArray(slot) ? (slot[0] + 'x' + slot[1] + '(' + slot[2] + ')') : ('Pause: ' + slot)
}

function slotsToText(slots) {
    return slots.map(slot => slotToText(slot)).join(', ')
}

function dayplanToText(dayplan) {
    return dayplan.open ? ('ab ' + timeToText(dayplan.time) + ' ' + slotsToText(dayplan.slots)) : 'geschlossen'
}

function weekLineToText(line) {
    return dowsToText(line.days) + ': ' + dayplanToText(line.plan)
}

function weekToText(week) {
    return (
        (week.date === 'default' ? '' : ('Ab ' + dateToText(week.date)) + ':\n') +
        week.dows.map(wl => weekLineToText(wl)).join('\n')
    )
}

function dayToText(day) {
    return dateToText(day.date) + ': ' + dayplanToText(day.plan)
}

function planToText(plan) {
    return plan.map(block => block.type === 'week' ? weekToText(block) : dayToText(block)).join('\n\n')
}

module.exports = module.exports || {}
module.exports.planToText = planToText
export default module.exports
