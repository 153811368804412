import { m } from '../../n2/components/base.js'
import { CrudTable } from '../../n2/components/crud.js'
import App from '../state/App.js'
import FramedPage from '../components/FramedPage.js'

export default class AdminTestToolsPage extends FramedPage {
    getTitle() {
        return 'Stammdaten'
    }

    getSubTitle() {
        return 'Testmittel'
    }

    renderMainComponent() {
        return m(CrudTable, {
            singularName: 'Testmittel',
            pluralName: 'Testmittel',
            dataSource: App.testTools,
            columns: [
                {
                    title: 'Name',
                    property: 'name',
                    type: 'string',
                    order: true,
                    filter: true
                },
                {
                    title: 'Hersteller',
                    property: 'vendor',
                    type: 'string',
                    order: true,
                    filter: true
                },
                {
                    title: 'Testmethode',
                    property: 'method',
                    type: 'string',
                    order: true,
                    filter: true
                }
            ],
            configureModel: (model) => {
                model.mandatory('name', true)
            },
            renderForm: (form) => form.group([
                form.string('name', 'Bezeichnung'),
                form.string('vendor', 'Hersteller'),
                form.string('method', 'Testmethode')
            ]),
            renderInfo: this.renderInfo
        })
    }
}