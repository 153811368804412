import MenuPage from '../components/MenuPage.js'
import {
    FactoryIcon,
    UsersIcon,
    PencilIcon,
    CalendarEventIcon,
    CalendarIcon
} from '../../n2/icons.js'

export default class AdminPage extends MenuPage {
    getMenuGroups() {
        return [
            {
                title: 'Verwaltung',
                entries: [
                    {
                        title: 'Testzentren',
                        route: '/admin/test-centers',
                        iconFace: FactoryIcon
                    },
                    {
                        title: 'Testmittel',
                        route: '/admin/test-tools',
                        iconFace: PencilIcon
                    },
                    {
                        title: 'Tester',
                        route: '/admin/testers',
                        iconFace: UsersIcon
                    },
                ]
            }
        ]
    }
}