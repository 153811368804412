import UserPage from './UserPage.js'

import AdminPage from './AdminPage.js'
import AdminTestCentersPage from './AdminTestCentersPage.js'
import AdminTestToolsPage from './AdminTestToolsPage.js'
import AdminTestersPage from './AdminTestersPage.js'

import TestCentersPage from './TestCentersPage.js'
import TestCenterPage from './TestCenterPage.js'

export default {
    UserPage,

    AdminPage,
    AdminTestCentersPage,
    AdminTestToolsPage,
    AdminTestersPage,

    TestCentersPage,
    TestCenterPage,
}
