import {m} from '../../n2/components/base.js'
import AppPage from './AppPage.js'
import {Layout} from '../../n2/components/layout.js'

const getParent = (p) => {
    const pp = p.split('/')
    pp.pop()
    return pp.join('/')
}

export default class FramedPage extends AppPage {
    constructor(vnode) {
        super(vnode)
    }

    getSecondaryLeftComponents() {
        return [
            this.constructor.parent && m(m.route.Link, { href: getParent(m.route.get()) },
                m('.text-white.bg-brand1.rounded-sm.p-1.px-4.items-center.font-bold',
                    m.trust('&#x21e6; Zurück')
                )
            )
        ]
    }

    getSecondaryRightComponents() {
        return [
        ]
    }

    renderSecondaryBar() {
        return m(Layout.HBox, [
            this.getSecondaryLeftComponents(),
            this.getSecondaryRightComponents()
        ])
    }

    renderMainComponent() {
        return null
    }

    renderChildren() {
        return m('.flex.flex-col.my-4', [
            this.renderSecondaryBar(),
            m('.flex.flex-col.flex-grow', this.renderMainComponent())
        ])
    }
}