import {Theme, m, Control, Icon} from '../../n2/components/base.js'
import {BottomBar} from '../../n2/components/layout.js'
import {Modal} from '../../n2/components/modal.js'
import {AvatarIcon, CalendarIcon, CogIcon, FactoryIcon} from '../../n2/icons.js'
import App from '../state/App.js'
import {TestIcon} from '../../shared/icons.js'

export default class AppFrame extends Control {
    constructor(vnode) {
        super(vnode, '.w-full.min-h-screen.flex.flex-col.items-center.bg-gray-light')
    }

    getTitle() {
        return this.attrs.title || ''
    }

    getSubTitle() {
        return this.attrs.subTitle || ''
    }

    renderBody() {
        return m('.w-full.h-full.relative.overflow-hidden', [
            m('.w-full.h-full.flex.flex-grow.justify-center.overflow-auto',
                this.vnode.children
            ),
            m('.pointer-events-none.absolute.left-0.w-full', { style: { height: '50px', top: '-50px' } }),
        ])
    }

    renderNavigationEntry(href, iconFace, text) {
        const route = m.route.get()
        const active = href === '/' ? href === route : route.startsWith(href)
        return m(m.route.Link, { href }, m('.flex.flex-row.mx-2', [
            text && m('.text-gray.flex.flex-row.items-center.mr-3', text),
            m(Icon, {
                iconFace,
                color: active ? Theme.colors.brand1.DEFAULT : Theme.colors.gray.DEFAULT,
                size: 40
            })
        ]))
    }

    renderTopBarComponents() {
        const centers = App.centers.all.array
        return App.user.isLoggedIn() ? [
            this.renderNavigationEntry('/user', AvatarIcon, App.user.getDisplayName()),
            App.user.isAdmin() && this.renderNavigationEntry('/admin', CogIcon),
            centers.length > 1 ? [
                this.renderNavigationEntry('/centers', FactoryIcon),
            ] : (centers.length > 0 && [
                this.renderNavigationEntry('/centers/' + centers[0].id, TestIcon),
            ])
        ] : []
    }

    renderChildren() {
        const children = this.renderTopBarComponents()
        return [
            m('.w-screen.h-150.bg-white', [
                m('.flex.flex-row.divide-x.divide-solid.divide-brand1', [
                    m('img.mt-2.mx-3.mb-3.h-8', {src: '/assets/logo-left.svg'}),
                    m('.flex-grow.flex.flex-row.content-end.items-center.pr-3', [
                        m('.flex.flex-col.flex-grow.justify-end.pl-5', [
                            m('.hidden.sm:block.align-text-bottom.text-2xl.leading-tight', [
                                m('span.text-title.font-bold.mr-1', this.getTitle().toUpperCase()),
                                m('span.text-title.text-brand1', this.getSubTitle().toUpperCase())
                            ])
                        ]),
                        ...children.slice(0, 1)
                    ]),
                    m('.flex.flex-row.items-center.pl-4.pr-4', children.slice(1))
                ]),
            ]),
            m('.container.mx-auto.px-4.py-10.flex.flex-1', [
                this.renderBody(),
            ]),
            m(BottomBar),
            Modal.view()
        ]
    }
}
