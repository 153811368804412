
import {m, Theme} from '../n2/components/base.js'
import SharedTheme from '../theme.js'
import CenterTheme from './theme.js'
import pages from './pages/index.js'
import LoginFrame from './components/LoginFrame.js'
import AppFrame from './components/AppFrame.js'
import App from './state/App.js'
import {LoginPage} from '../shared/pages/userPages.js'

async function ensureLoaded(render) {
    await App.user.ensure()
    return { view: render }
}

async function ensureLoggedIn(render) {
    await App.user.ensure()
    if (!App.user.isLoggedIn()) {
        m.route.set('/login')
    } else {
        return { view: render }
    }
}

async function ensureAdmin(render) {
    await App.user.ensure()
    if (!App.user.isAdmin()) {
        m.route.set('/centers')
    } else return { view: render }
}

const loginPage = (component, title, subTitle, attrs) => ({
    onmatch: () => ensureLoaded(() => m(LoginFrame, { title, subTitle }, m(component, attrs)))
})

const internalPage = (component, title, subTitle, attrs) => ({
    onmatch: () => ensureLoggedIn(() => m(AppFrame, { title, subTitle: subTitle !== null ? subTitle : attrs.getSubtitle() }, m(component, attrs)))
})

const adminPage = (component, title, subTitle, attrs) => ({
    onmatch: () => ensureAdmin(() => m(AppFrame, { title, subTitle }, m(component, attrs)))
})

Theme.init(SharedTheme, CenterTheme)

m.route.prefix = '#'
m.route(document.body, '/login', {
    '/login' : loginPage(LoginPage, 'Anmeldung', '', {
        user: App.user,
        next: '/centers',
    }),
    '/user' : internalPage(LoginPage, 'Nutzer', '', {
        user: App.user,
        next: '/login',
    }),
    '/admin' : adminPage(pages.AdminPage, 'Administration'),
    '/admin/testers' : adminPage(pages.AdminTestersPage, 'Administration', 'Tester'),
    '/admin/test-centers' : adminPage(pages.AdminTestCentersPage, 'Administration', 'Testzentren'),
    '/admin/test-tools' : adminPage(pages.AdminTestToolsPage, 'Administration', 'Testmittel'),
    '/centers' : internalPage(pages.TestCentersPage, 'Testzentren', ''),
    '/centers/:center' : internalPage(pages.TestCenterPage, 'Testzentrum', null, {
        getSubtitle: () => {
            const center = App.centers.all.byId[Number(m.route.param('center'))]
            return center && center.name
        }
    }),
})
