import {Control, Input, m} from './base.js'
import { Layout } from './layout.js'
import TableModel from '../data/TableModel.js'
import Options from '../data/Options.js'
import {Select} from './pick.js'


export class DataTable extends Control {
    constructor(vnode) {
        super(vnode, '.mt-4.mb-6.px-8.pt-4.pb-6.flex.flex-grow.justify-center.bg-white.rounded-sm.shadow-lg.overflow-hidden')
        this.model = new TableModel(vnode.attrs.dataSource)
        this.ensurePage = () => { // storing in object to prevent GC collecting it
            this.verifyPage()
            this.model.ensurePage(this.page)
        }
        this.model.onInvalidate.register(this.ensurePage)
        this.model.setPageSize(vnode.attrs.pageSize || 10)
        this.page = 0
        this.pageEntities = []
    }

    getModel() {
        return this.model
    }

    getPageSize() {
        return this.model.getPageSize()
    }

    setPageSize(pageSize) {
        this.model.setPageSize(pageSize)
    }

    getPage() {
        return this.page
    }

    setPage(page) {
        page = Math.max(0, Math.min(page, this.model.getNumberOfPages() - 1))
        if (page !== this.page) {
            this.page = page
            this.pageEntities = []
            this.model.ensurePage(page)
        }
    }

    verifyPage() {
        this.setPage(this.page)
    }

    getPageEntities() {
        if (this.model.getPageState(this.page) === 'loaded'){
            this.pageEntities = this.model.getPageEntities(this.page)
        }
        return this.pageEntities
    }

    hasPreviousPage() {
        return this.page > 0
    }

    hasNextPage() {
        return this.page < this.model.getNumberOfPages() - 1
    }

    nextPage() {
        if (this.hasNextPage()) this.setPage(this.page + 1)
    }

    previousPage() {
        if (this.hasPreviousPage()) this.setPage(this.page - 1)
    }

    getColumns() {
        return this.attrs.columns || []
    }

    renderTopLeft() {
        return null
    }

    renderTopRight() {
        return null
    }

    renderBottomLeft() {
        const total = this.model.getTotalNumberOfEntities()
        const current = this.model.getNumberOfEntities()
        return m(
            '.font-bold',
            total === current ?
                total + (total === 1 ? ' Datensatz' : ' Datensätze') :
                current + ' von ' + total + ' Datensätzen'
        )
    }

    renderBottomRight() {
        return this.model.getNumberOfPages() > 1 ? m(Layout.PaddedHBox, [
            m(
                '.text-xl.font-bold.hover:text-brand1.select-none',
                {
                    onclick: () => this.previousPage()
                },
                m.trust('&#x1438;')
            ),
            m('div', 'Seite'),
            m(Input, { class: 'text-center w-10 px-1', value: this.page + 1, onChange: (value) => this.setPage(value - 1) }),
            m('div', 'von ' + this.model.getNumberOfPages()),
            m(
                '.text-xl.font-bold.hover:text-brand1.select-none',
                {
                    onclick: () => this.nextPage()
                },
                m.trust('&#x1433;')
            )
        ]) : '1 Seite'
    }

    renderSortButton(column, index) {
        const mode = this.model.getOrderByProperty() === column.property ? this.model.getOrderByMode() : 'none'
        return m('.relative.ml-1.text-brand1', [
            m('.absolute.top-0.left-0', { class: mode !== 'desc' ? 'text-gray-light' : '' }, m.trust('&#x2303;')),
            m('.absolute.top-0.left-0', { class: mode !== 'asc' ? 'text-gray-light' : '' }, m.trust('&#x2304;'))
        ])
    }

    renderHeaderCell(column, index) {
        const attrs = column.order ? {
            onclick: () => {
                if (column.property !== this.model.getOrderByProperty()) {
                    this.model.setOrderByProperty(column.property)
                    if (this.model.getOrderByMode() === 'none')
                        this.model.setOrderByMode('asc')
                } else {
                    this.model.setOrderByMode(
                        this.model.getOrderByMode() === 'asc' ?
                            'desc' :
                            (this.model.getOrderByMode() === 'none' ? 'asc' : 'none')
                    )
                }
            }
        } : {}
        return m(
            'th.sticky.top-0.text-left.pr-5',
            Object.assign(attrs, column.headerAttributes || {}),
            m(Layout.HBox,[
                column.title,
                column.order ? this.renderSortButton(column, index) : null
            ])
        )
    }

    renderHeaderRow() {
        return m(
            'tr.font-bold',
            this.getColumns().map((c, i) => this.renderHeaderCell(c, i))
        )
    }

    renderDefaultFilterCellContent(filter, column, columnIndex) {
        return m(Input, {
            type: 'text',
            onChange: (value) => {
                const newValue = value && value.length > 0 ? value : undefined
                if (filter.timer) clearTimeout(filter.timer)
                filter.timer = setTimeout(() => filter.setValue(newValue), 100)
            }
        })
    }

    renderBoolFilterCellContent(filter, column, columnIndex) {
        return null
    }

    renderDateFilterCellContent(filter, column, columnIndex) {
        return null
    }

    renderReferenceFilterCellContent(filter, column, columnIndex) {
        filter.setOperation('=')
        return m(Select, {
            placeholder: 'Alle',
            options: column.lookup,
            value: filter.getValue(),
            onChange: value => filter.setValue(value)
        })
    }

    renderTypedFilterCellContent(filter, column, columnIndex) {
        let renderer = (column.type || 'default')
        renderer = 'render' + renderer.charAt(0).toUpperCase() + renderer.slice(1) + 'FilterCellContent'
        renderer = this[renderer] || this.renderDefaultFilterCellContent
        return renderer.apply(this, [filter, column, columnIndex])
    }

    renderFilterCellContent(column, columnIndex) {
        const filter = this.model.getPropertyFilters(column.property)[0] ||
            this.model.addPropertyFilter(column.property, 'LIKE')
        return column.renderFilterCell ?
            column.renderFilterCell(filter, column, columnIndex) :
            this.renderTypedFilterCellContent(filter, column, columnIndex)
    }

    renderFilterCell(column, columnIndex) {
        return m(
            'th.sticky.top-0.text-left.pb-1.pr-2.font-normal',
            column.headerAttributes || {},
            column.filter ? this.renderFilterCellContent(column, columnIndex) : null
        )
    }

    renderFilterRow() {
        return m(
            'tr.border-b.border-gray',
            this.getColumns().map((c, i) => this.renderFilterCell(c, i))
        )
    }

    renderDefaultCellContent(entity, column, index, columnIndex) {
        return entity[column.property]
    }

    renderBoolCellContent(entity, column, index, columnIndex) {
        return null
    }

    renderDateCellContent(entity, column, index, columnIndex) {
        return null
    }

    renderReferenceCellContent(entity, column, index, columnIndex) {
        const value = entity[column.property]
        return column.lookup ? Options.getSelectedLabel(column.lookup, value) : value
    }

    renderTypedCellContent(entity, column, index, columnIndex) {
        let renderer = (column.type || 'default')
        renderer = 'render' + renderer.charAt(0).toUpperCase() + renderer.slice(1) + 'CellContent'
        renderer = this[renderer] || this.renderDefaultCellContent
        return renderer.apply(this, [entity, column, index, columnIndex])
    }

    renderCellContent(entity, column, index, columnIndex) {
        return column.renderCell ?
            column.renderCell(entity, column, index, columnIndex) :
            this.renderTypedCellContent(entity, column, index, columnIndex)
    }

    renderCell(entity, column, index, columnIndex) {
        return m(
            'td.pr-2.cursor-default',
            Object.assign({
                onclick: () => this.onCellClick(entity, column, index, columnIndex)
            }, column.cellAttributes || {}),
            this.renderCellContent(entity, column, index, columnIndex)
        )
    }

    onCellClick(entity, column, index, columnIndex) {

    }

    renderRow(entity, index) {
        return m(
            'tr.hover:bg-brand1-lightest',
            {
                className: index % 2 === 0 ? 'bg-white' : 'bg-gray-lightest'
            },
            this.getColumns().map((c, i) => this.renderCell(entity, c, index, i))
        )
    }

    renderTable() {
        return m('table.border-collapsed.w-full',
            m('thead.select-none', [
                this.renderHeaderRow(),
                this.renderFilterRow()
            ]),
            m('tbody.overflow-y-scroll',
                this.getPageEntities().map((e, i) => this.renderRow(e, i))
            )
        )
    }
    
    getAttributes() {
        return Object.assign({
            style: {
                minWidth: '800px'
            }
        }, super.getAttributes())
    }
    
    renderChildren() {
        return m(Layout.VTriple, [
            m(Layout.HTriple, [this.renderTopLeft(), this.renderTopRight()]),
            m('.py-4', this.renderTable()),
            m(Layout.HTriple, [this.renderBottomLeft(), this.renderBottomRight()])
        ])
    }
}
